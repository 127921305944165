// @ts-check
import { ShopstoryProvider } from "@shopstory/core/react"
import { GatsbyImage } from "gatsby-plugin-image"
import React, { forwardRef, useMemo } from "react"
import { Button } from "../components/v2/Button"
import { Link } from "../components/v2/Link"
import ProductHighlight from "../components/new-ui/product-highlight"
import Tile from "../components/new-ui/tile"
import Product from "../components/Page/ContentModules/Product"
import NewProduct from "../components/Page/ContentModules/Product/new-product"
import { useStore } from "../context/NewStoreContext"
import { trackProductClicked } from "../services/analytics"
import { getProductDetails } from "../utils/functions/product-util"
import { getMinimumPriceOption } from "../utils/prices"
import { ContentfulTypes } from "../utils/types"
import { generateGatsbyImageData } from "./gatsby/imageUtils"
import { getTitle } from "../components/v2/utils/product/getTitle"
import { getBunnyImageSrc, getGatsbyImageBunnySrc } from "../utils/bunny-cdn"

const gatsbyImageStyle = { height: "100%" }
const gatsbyImageImgStyle = { width: "100%", height: "100%" }

/** @type {import('@shopstory/core').CustomInstances} */
const customInstances = {
  actions: {},
  buttons: {
    Button: forwardRef(function ButtonRender({ label, ...restProps }, ref) {
      return (
        <Button ref={ref} {...restProps}>
          {label}
        </Button>
      )
    }),
  },
  components: {
    ProductCard: ({ product, variant, ...restProductProps }) => {
      const { cart } = useStore()
      const { showName, image, imageOnHover } = restProductProps

      const imageGatsbyData = useMemo(() => {
        if (image) {
          return generateGatsbyImageData({
            imageUrl: getBunnyImageSrc(image.url),
            width: image.srcset[0].w,
            height: image.srcset[0].h,
          })
        }
      }, [image])

      const imageOnHoverGatsbyData = useMemo(() => {
        if (imageOnHover) {
          return generateGatsbyImageData({
            imageUrl: getBunnyImageSrc(imageOnHover.url),
            width: imageOnHover.srcset[0].w,
            height: imageOnHover.srcset[0].h,
          })
        }
      }, [imageOnHover])

      const { collection, ...restProductData } = product
      function handleProductClick() {
        trackProductClicked(restProductData, collection)
      }

      if (variant === "highlight") {
        const {
          showProductTitle,
          showProductColor,
          showProductPrice,
          showProductTeaser,
        } = restProductProps

        return (
          <ProductHighlight
            product={product}
            onProductClick={handleProductClick}
            className="item product-highlight"
            showProductTitle={showProductTitle}
            showProductColor={showProductColor}
            showProductPrice={showProductPrice}
            showProductTeaser={showProductTeaser}
            image={imageGatsbyData}
            imageOnHover={imageOnHoverGatsbyData}
          />
        )
      }

      if (variant === "tile") {
        const minimumPrice = getMinimumPriceOption(
          getProductDetails(product),
          cart
        )

        const productType = getProductType(product)

        return (
          <NewProduct
            productType={productType}
            product={{ ...restProductData, minimumPrice }}
            onProductClick={handleProductClick}
            showName={showName}
            image={imageGatsbyData}
            imageOnHover={imageOnHoverGatsbyData}
          />
        )
      }

      return (
        <Product
          product={product}
          onProductClick={handleProductClick}
          showName={showName}
          image={imageGatsbyData}
          imageOnHover={imageOnHoverGatsbyData}
        />
      )
    },
    ProductTile: ({
      image,
      mode,
      product,
      linkTo,
      imageText,
      title,
      hideTitle,
    }) => {
      const imageGatsbyData = useMemo(() => {
        return generateGatsbyImageData({
          imageUrl: getBunnyImageSrc(image.url),
          width: image.srcset[0].w,
          height: image.srcset[0].h,
        })
      }, [image])

      const tileTitle = title ? title : getTitle(product)

      const input = {
        content: [
          {
            internal: {
              type: ContentfulTypes.ContentfulAssetWrapper,
            },
            asset: {
              gatsbyImageData: imageGatsbyData,
              file: {
                contentType: image.mimeType,
              },
            },
            imageText,
            reference: mode === "productReference" ? product : undefined,
            linkTo: mode === "customLink" ? linkTo : undefined,
          },
        ],
        title: tileTitle,
        hideTitle,
      }

      return (
        <Tile
          input={input}
          sx={undefined}
          assetTitle={image?.title}
          className={undefined}
        />
      )
    },
  },
  links: {
    GatsbyLink: {
      provider: ({ Component, componentProps, values }) => {
        return (
          <Link to={values.pagePath}>
            <Component {...componentProps} as="span" />
          </Link>
        )
      },
    },
    ProductLink: {
      provider: ({ Component, componentProps, values }) => {
        const { product } = values

        if (!product?.handle) {
          console.log(
            `Product handle is missing for product with id ${product?.id}`
          )

          return null
        }

        return (
          <Link to={`/product/${product.handle}`}>
            <Component {...componentProps} as="span" />
          </Link>
        )
      },
    },
  },
  Image: ({ src, alt }) => {
    const imageData = useMemo(() => {
      return generateGatsbyImageData({ imageUrl: src })
    }, [src])

    return (
      <GatsbyImage
        image={getGatsbyImageBunnySrc(imageData)}
        alt={alt}
        style={gatsbyImageStyle}
        imgStyle={gatsbyImageImgStyle}
        title={alt}
      />
    )
  },
}

function TeklaShopstoryProvider({ children }) {
  return <ShopstoryProvider {...customInstances}>{children}</ShopstoryProvider>
}

export { TeklaShopstoryProvider }

function getProductType(product) {
  if (product.internal.type === ContentfulTypes.ContentfulBeddingBundle) {
    if (product.duvetProduct) {
      return "duvet"
    }

    if (product.pillowProduct) {
      return "pillow"
    }

    if (product.flatSheetProduct) {
      return "sheet"
    }

    return "duvet"
  }

  return ""
}
