// External packages
import * as React from "react"
import { Box, Paragraph } from "theme-ui"

// Types
import { ContentfulImage } from "types"

// Components
import { Picture } from "../Picture"
import { getBunnyImageSrc, getBunnyVideoSrc } from "../../../utils/bunny-cdn"

export const Image: React.FC<{ data: ContentfulImage }> = ({ data }) => (
  <Box
    sx={{
      marginBlockStart: data?.topMargin,
      marginBlockEnd: data?.bottomMargin,
    }}
  >
    {data?.asset?.file?.contentType?.startsWith("video") ? (
      <Box sx={{ video: { display: "block", width: "100%" } }}>
        <video
          src={getBunnyVideoSrc(data?.asset?.file?.url)}
          autoPlay
          muted={true}
          loop
          playsInline
        >
          <p>Your browser doesn't support HTML video.</p>
        </video>
      </Box>
    ) : (
      <Picture
        sources={data?.asset?.gatsbyImageData?.images?.sources}
        imageProps={{
          width: data?.asset?.gatsbyImageData?.width,
          height: data?.asset?.gatsbyImageData?.height,
          sizes: data?.asset?.gatsbyImageData?.images?.fallback?.sizes,
          src: getBunnyImageSrc(
            data?.asset?.gatsbyImageData?.images?.fallback?.src
          ),
          srcSet: getBunnyImageSrc(
            data?.asset?.gatsbyImageData?.images?.fallback?.srcSet
          ),
          alt: data?.asset?.description,
        }}
      />
    )}
    {Boolean(data?.showDescription) ? (
      <Box sx={{ paddingBlockStart: 1, paddingBlockEnd: [6, 8] }}>
        <Paragraph sx={{ fontSize: "xxs" }}>
          {data?.asset?.description}
        </Paragraph>
      </Box>
    ) : null}
  </Box>
)
