// External packages
import * as React from "react"
import { Box, Flex, FlexProps, Heading, Paragraph } from "theme-ui"

// Types
import { ContentfulGrid, ContentfulWidgetLink } from "types"

// Utilities
import { getContentfulUrl } from "./utils"

// Components
import { UiGridItem } from "../ui/Contentful"
import { Button } from "../Button"
import { Picture } from "../Picture"
import { Link } from "../Link"
import { getBunnyImageSrc, getBunnyVideoSrc } from "../../../utils/bunny-cdn"

export const WidgetLink: React.FC<{
  data: ContentfulWidgetLink
  isInCategoryTemplate?: boolean
  index?: number
  gridItems?: ContentfulGrid["gridItems"]
  mobileLayout?: ContentfulGrid["mobileLayout"]
  hasStickyTitles?: ContentfulGrid["stickyTitles"]
}> = ({
  data,
  isInCategoryTemplate = false,
  index,
  gridItems,
  mobileLayout,
  hasStickyTitles,
}) => {
  return isInCategoryTemplate && !Boolean(data?.image) ? (
    <WidgetLinkText data={data} isInCategoryTemplate />
  ) : !isInCategoryTemplate && !Boolean(data?.image) ? (
    <UiGridItem
      index={index}
      gridItems={gridItems}
      mobileLayout={mobileLayout}
      sx={{
        height: ["auto", "100%"],
        paddingBlockStart:
          mobileLayout === "Vertical" && index === 0
            ? 25
            : mobileLayout === "Vertical"
            ? 19
            : null,
        paddingBlockEnd: mobileLayout === "Vertical" ? 25 : null,
      }}
    >
      <WidgetLinkText data={data} isInCategoryTemplate={false} />
    </UiGridItem>
  ) : isInCategoryTemplate && Boolean(data?.image) ? (
    <WidgetLinkImage
      data={data}
      hasStickyTitles={hasStickyTitles}
      isInCategoryTemplate
    />
  ) : !isInCategoryTemplate && Boolean(data?.image) ? (
    <UiGridItem index={index} gridItems={gridItems} mobileLayout={mobileLayout}>
      <WidgetLinkImage data={data} hasStickyTitles={hasStickyTitles} />
    </UiGridItem>
  ) : null
}

const WidgetLinkText: React.FC<
  FlexProps & {
    data: ContentfulWidgetLink
    isInCategoryTemplate: boolean
  }
> = ({ data, isInCategoryTemplate, sx, ...props }) => (
  <Flex
    {...props}
    sx={{
      ...sx,
      width: ["100%", "75%"],
      maxWidth: 84,
      height: "100%",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
      gridColumn:
        isInCategoryTemplate && data?.size === "2x2" ? "1 / span 2" : null,
      gridRow: isInCategoryTemplate && data?.size === "2x2" ? "span 2" : null,
      marginInline: "auto",
    }}
    data-textual
  >
    <Heading sx={{ fontSize: ["lg", "xl"] }}>{data?.title}</Heading>
    {Boolean(data?.description) ? (
      <Paragraph sx={{ fontSize: "sm", marginBlockStart: [3, 4] }}>
        {data?.description}
      </Paragraph>
    ) : null}
    <Button
      variant="ghost"
      href={getContentfulUrl({ linkUrl: data?.linkUrl, link: data?.link })}
      sx={{ marginBlockStart: [4, 5] }}
    >
      {data?.buttonLabel}
    </Button>
  </Flex>
)

const WidgetLinkImage = ({
  data,
  hasStickyTitles,
  isInCategoryTemplate,
}: {
  data: ContentfulWidgetLink
  hasStickyTitles?: ContentfulGrid["stickyTitles"]
  isInCategoryTemplate?: boolean
}) => (
  <Link
    to={getContentfulUrl({ linkUrl: data?.linkUrl, link: data?.link })}
    sx={{
      display: "flex",
      flexDirection: "column",
      height: "100%",
      gridColumn:
        isInCategoryTemplate && data?.size === "2x2" ? "1 / span 2" : null,
      gridRow: isInCategoryTemplate && data?.size === "2x2" ? "span 2" : null,
    }}
  >
    <Box
      data-image
      sx={{
        position: "relative",
        width: "100%",
        aspectRatio: "4 / 5",
      }}
    >
      {Boolean(data?.image) ? (
        data?.image?.file?.contentType?.startsWith("video") ? (
          <Box
            sx={{
              height: "100%",
              video: {
                display: "block",
                width: "100%",
                height: "100%",
                objectFit: "cover",
              },
            }}
          >
            <video
              src={getBunnyVideoSrc(data?.image?.file?.url)}
              autoPlay
              muted={true}
              loop
              playsInline
            >
              <p>Your browser doesn't support HTML video.</p>
            </video>
          </Box>
        ) : (
          <Picture
            sources={data?.image?.gatsbyImageData?.images?.sources}
            sx={{ height: "100%" }}
            imageProps={{
              width: data?.image?.gatsbyImageData?.width,
              height: data?.image?.gatsbyImageData?.height,
              sizes: data?.image?.gatsbyImageData?.images?.fallback?.sizes,
              src: getBunnyImageSrc(
                data?.image?.gatsbyImageData?.images?.fallback?.src
              ),
              srcSet: getBunnyImageSrc(
                data?.image?.gatsbyImageData?.images?.fallback?.srcSet
              ),
              alt: data?.title,
            }}
          />
        )
      ) : null}
    </Box>
    <Box
      data-footer
      sx={{
        position: Boolean(data?.title) && hasStickyTitles ? "sticky" : null,
        bottom: Boolean(data?.title) && hasStickyTitles ? 0 : null,
        width: "100%",
        backgroundColor:
          Boolean(data?.title) && hasStickyTitles ? "grayscale.100" : null,
        paddingBlockStart: 2,
        paddingBlockEnd: [6, 8],
      }}
    >
      {Boolean(data?.title) ? (
        <Paragraph
          sx={{
            fontSize: "sm",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
        >
          {data?.title}
        </Paragraph>
      ) : null}
    </Box>
  </Link>
)
